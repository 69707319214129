/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our sense of hearing is a basic human need that affects everything we do. We rely on our hearing to communicate with loved ones, and even more importantly, to warn us of oncoming danger. The declared aim of the Westphalian ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid manufacturer"), ", Audio Service, is to help people with hearing loss enjoy life at the right volume. Audio Service’s focus is on customer service, entrepreneurship, and innovation. With these values in mind, Audio Service is committed to providing ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "high-quality hearing aids"), " for people experiencing signs of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The information below is a brief overview of Audio Service hearing aids and their features. To find out if we currently carry Audio Service hearing aids, feel free to give us a call. We would be glad to help you with all your hearing needs."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "virtually-invisible-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#virtually-invisible-hearing-aids",
    "aria-label": "virtually invisible hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Virtually invisible hearing aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing aids are big, bulky, and hang behind the ear where everyone can easily notice them. This is one of the many common misconceptions surrounding hearing aids. One of the core competencies of the Herford-based manufacturer, Audio Service, is the ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "in-the-ear hearing aids"), " (ITE). Many of the state-of-the-art ITEs feature the same technical specifications and settings as behind-the-ear hearing aids. Nowadays, these hearing aids can be placed deep enough in the ear canal that they are virtually invisible. So, now you can hear better without people knowing about your hearing devices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Through the ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "most innovative technology"), " developed in Germany, Audio Service designed modern ITEs that minimally obstruct the ear canal, thus improving the level of comfort for the wearer. Another notable characteristic of Audio Service hearing aids is the automatic program adjustment. In other words, these devices intuitively know if you are sitting in a restaurant or walking through a park. Depending on the acoustics of a particular setting, these hearing aids are capable of adjusting the audio program to adequately highlight and accentuate sounds in your environment. Hence, Audio Service hearing aids help you to experience the most natural and brilliant sound quality. This is just one of the many new developments coming from the in-house research and development team in Herford, Germany."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Audio Service is not the only manufacturer making invisible hearing aids. Call us today to learn about the newest invisible hearing aids from the leading manufacturers."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "from-specialist-for-in-the-ear-hearing-aids-to-global-enterprise",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#from-specialist-for-in-the-ear-hearing-aids-to-global-enterprise",
    "aria-label": "from specialist for in the ear hearing aids to global enterprise permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "From specialist for In-the-ear hearing aids to global enterprise"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the late 1970s, the hearing-system specialist Horst Peter Kühne found a company in Germany that we now know as Audio Service. During a visit to America, Kühne learned about the advantages associated with in-the-ear hearing aids. When he returned to Germany, he set out to start his own company specializing in the production of in-the-ear hearing aids. It only took a few years for Audio Service to outgrow their facilities, so the company moved to Herford. Roughly after a decade of its existence, the production operation expanded to include behind-the-ear hearing aids. After this expansion, a large number of innovations followed like protective and acoustic filters in the 1990s, and special coatings to repel dirt and bacteria in the early 2000s. Today, Audio Service ranks among the largest manufacturers of hearing aids and related accessories."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "wireless-20-and-bluetooth-connectivity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#wireless-20-and-bluetooth-connectivity",
    "aria-label": "wireless 20 and bluetooth connectivity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Wireless 2.0 and Bluetooth connectivity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The latest morning news on the radio, music during lunch break, cell phone conversations in the afternoon, watching a movie in the evening: The Mobile Connect Set from Audio Service allows you to do all these things with your hearing aids – without the need of any additional cables. This feature is a whole new dimension of modern communication and entertainment technology, thanks to the new Wireless 2.0 technology and ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connectivity.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Be it a cellphone, radio, hi-fi system, television, or navigation system, the Mobile Connect Set makes connecting to the most diverse range of devices possible. All audio signals from the multimedia devices are directly transmitted to the hearing aids. Two essential components of the Mobile Connect Set make this possible: Mobile Connect and Mobile Station. Audio Service enables its customers to experience crisp and clear sound with these connectivity options and state-of-the-art technology."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "audio-service-vs-other-manufacturers",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#audio-service-vs-other-manufacturers",
    "aria-label": "audio service vs other manufacturers permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Audio Service v/s Other manufacturers"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Audio Service is a high quality brand giving competition to other big brand manufacturers such as Phonak, ReSound and Oticon. Audio Service products provide the same level of technology, in some cases even more advanced technology than their competitors. The products are competitively priced and customers have rated them as high quality hearing systems that support them completely. You can buy their hearing aids in the same way as other manufacturers. You would need to book an appointment with the Audiologist who would then conduct a ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "hearing test"), ", followed by the fitting of hearing aids. Most audiologists offer Audio Service so you can easily get them, just let your ear specialist know when they are recommending devices so they order devices of your choice."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
